section .buy-api-key-container {
  width: 80%;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  .card {
    width: 100%;
    height: 60px;
    padding: 0 1rem;
    border: 2px solid $color-gray;
    border-radius: 5px;
    display: grid;
    gap: 0.2rem;
    align-items: center;
    grid-template-columns: 1fr 50px 100px;

    p {
      text-align: right;
    }

    button {
      float: right;
      @include submit-button();
      border: 1px solid $color-blueish;
      border-radius: 3px;
    }

  }
}
