footer {
  padding: 50px 20px;
  margin-top: 100px;
  width: 100%;
  background: $color-pale-blue;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  & > .footer-area {
    flex-basis: 30%;

    & > * {
      display: block;
    }
  }

  h4 {
    font-weight: bold;
  }

  button, a {
    color: black;
    text-decoration: underline;
  }

  @media only screen and (max-width: $bp-tablet) {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;

    & > .footer-area {
      flex-basis: 45%;
    }
  }

  @media only screen and (max-width: $bp-phone) {
    padding: 20px 20px;

    & > .footer-area {
      flex-basis: 100%;
    }
  }
}

.footer-copyright {
  background: $color-pale-blue;
  text-align: center;
  font-size: 0.8rem;
}
