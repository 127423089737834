section .api-keys-transactions-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  & > button {
    align-self: flex-end;
    margin-bottom: 1rem;
    @media only screen and (max-width: $bp-tablet){
      align-self: center;
    }
  }

  .api-keys-card,
  .transactions-card {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:not(:first-child) {
      padding: 1.5rem 0 0 0;
      border-top: 1px solid $color-gray;
    }
  }

  .api-keys-card {
    .api-key-info {
      display: flex;
      gap: 1rem;

      p.pro-key::after {
        content: 'Pro Key';
        font-weight: bold;
      }
    }

    .api-key-container {
      display: grid;
      grid-template-columns: 5fr 1fr 2fr;
      align-items: center;
      padding: 0.5rem 0 0.5rem 0;
      gap: 1rem;

      .token {
        font-size: 0.7rem;
        word-wrap: break-word;
        width: 100%;
      }

      .times-used {
        text-align: center;
      }

      p {
        cursor: auto;
        word-wrap: break-word;
      }

      .button-cont {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;

        button {
          color: $color-blue;
          padding: 0.2rem;
          transition: all 0.2s ease;

          &:hover {
            color: $color-light-blue;
          }
        }
      }

      @media only screen and (max-width: $bp-tablet) {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 0.3rem;

        .times-used {
          align-self: self-start;
        }

        .button-cont {
          width: 100%;
          padding: 0;
          align-self: self-start;
          justify-content: space-between;
          gap: 0;

          button {
            padding: 0;
          }
        }
      }
    }
  }

  .transactions-card {
    div {
      & > * {
        display: inline;
      }

      button {
        color: $color-blue;
      }
    }
  }
}