.sign-in-up-page {
  width: 100%;
  margin-top: 150px;

  & .wave-wrapper {
    top: 50px;
  }

  section {
    width: 400px;
    margin: auto;

    h2 {
      font-size: 2rem;
      text-align: center;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    span {
      font-size: 0.8rem;
    }

    @include card();

    .small {
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
      margin-bottom: 5px;

      button {
        color: $color-blue;
        @include hover-underline(1px, $color-blue);
      }
    }

    .agree-to-policies {
      font-size: 0.8rem;
      margin-top: 10px;
    }
  }

  @media only screen and (max-width: $bp-phone) {
    margin-top: 50px;

    & .wave-wrapper {
      top: -30px;
    }

    section {
      width: 90%;
    }
  }
}
