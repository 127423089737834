section.update-password-request {
  max-width: 400px;
  margin: 100px auto;
  @include card();

  h2 {
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

section.update-password {
  max-width: 400px;
  margin: 100px auto;
  @include card();
}
