.tos-container {
  width: 90%;
  margin: 0 auto 30px auto;

  & p {
    max-width: 60ch;
  }

  & h1 {
    font-size: 2.5rem;
    font-weight: bold;
  }

  & h2 {
    margin-top: 1rem;
    font-size: 1.7rem;
  }
}
