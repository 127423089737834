@mixin hover-underline($height, $back-color) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: $height;
    bottom: 0;
    left: 0;
    background-color: $back-color;
    border-radius: 3%/25%;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all .35s cubic-bezier(1, .25, 0, .75);
    transition: all .2s cubic-bezier(1, .25, 0, .75);
  }
  &:focus {
    outline: 0;
  }
  &:hover:before, &:focus:before {
    visibility: visible;
    -webkit-transform: scaleX(1) translateY(4px);
    transform: scaleX(1) translateY(4px);
  }
}

@mixin card() {
  .card {
    font-size: 1.3rem;
    width: 100%;
    @include blue-card();
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;

    & > input {
      padding: 0 0.2rem 0.1rem;
      width: 75%;
      height: 2.5rem;
      font-size: 1.1rem;
      border: 2px solid $color-blueish;
      border-radius: 5px;
      background: white;
    }

    & > input:focus {
      outline: 0;
      border: 2px solid $color-light-blue;
    }

    & > input[type="password"] {
      letter-spacing: -5px;
      padding: 0 0.2rem;
    }

    & > p {
      text-align: center;
    }

    & > p:not(:first-child) {
      margin-top: 1rem;
    }

    & > button {
      margin: 1rem 0 0 0;
      @include submit-button();
    }
  }
}

@mixin blue-card() {
  background: $color-pale-blue2;
  box-shadow: 0 0 5px -1px $color-light-blue;
  border: 1px solid $color-light-blue;
  border-radius: 5px;
}

@mixin submit-button() {
  background: $color-light-blue;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  border: 3px solid $color-blueish;
  transition: all 0.3s ease-in-out;

  &:hover, &:focus {
    background: $color-blueish;
  }
}

@mixin header(){
  width: 90%;
  height: 80px;
  margin: 0 auto 1rem auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  h1 {
    font-weight: 600;
    font-size: 2.5rem;
  }

  @media only screen and (max-width: $bp-phone) {
    width: 100%;
  }
}
