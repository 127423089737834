.cookie-popup {
  background: $color-light-blue;
  width: 100%;
  padding: 1.3em 3em;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  gap: 0.5rem;

  p {
    font-size: 1.2rem;
    font-weight: bold;
  }

  button{
    @include submit-button;
    font-weight: bold;
    font-size: 1.2rem;
  }

  &.hidden {
    bottom: -100px;
  }

  @media only screen and (max-width: $bp-tablet){
    padding: 1.3em 1em;
    p {
      font-size: 1rem;
      font-weight: bold;
    }
  }
}
