.api-ad {
  padding-block: 1.8rem;
  font-size: 1.8rem;
  text-align: center;
  background-color: $color-blueish;
  color: black;

  a {
    color: black;
  }
  a:visited {
    color: inherit;
  }

  @media only screen and (max-width: $bp-tablet) {
    padding-block: 1.4rem;
  font-size: 1.4rem;
  }
}

.docs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .info {
    background: $color-light-blue;
    flex-basis: 50%;
    padding: 2em 1em;

    .line1 {
      display: flex;
      align-items: center;
      gap: 1em;

      p {
        font-size: 0.9em;
        margin-bottom: 0.1em;
      }

      button {
        color: $color-blue;
        padding: 0.3em 0.4em;
        border-radius: 10px;
        border: 1px solid $color-blue;
        transition: background-color 0.5s ease, color 0.1s ease;

        &:hover {
          background-color: $color-blueish;
        }

        &:active {
          color: $color-light-blue;
        }
      }

      .method {
        background: #4a4afc;
        border-radius: 8px;
        padding: 0.2em 0.4em;
        font-weight: bold;
        color: white;
      }
    }

    .request {
      padding: 0 0.5em;

      & > *:nth-child(odd) {
        margin-top: 0.5em;
      }

      .headers-container {
        border: 1px solid black;
      }

      .path-params {
        border: 1px solid black;
      }

      .path-params,
      .headers {
        display: flex;
        gap: 2em;
        padding: 0.5em;
        font-size: 0.9rem;

        .header,
        .param {
          font-weight: bold;
          min-width: 6em;
        }

        .type {
          color: white;
        }

        .required::after {
          content: "Required";
          display: block;
          font-size: 0.8em;
        }
      }
    }
  }

  .examples {
    flex-basis: 50%;
    background: #576bec;
    display: grid;
    place-items: center;
    padding: 10px 0;

    .container {
      height: auto;
      min-height: 220px;
      width: 75%;
      max-width: 700px;
      border: 2px solid $color-blueish;
      border-radius: 5px;
      background: #d7cccc;
      font-size: 0.8rem;
      letter-spacing: 0.8px;
      & * {
        font-family: $font1;
      }
    }
  }

  @media only screen and (max-width: $bp-tablet) {
    .info {
      flex-basis: 100%;
    }

    .examples {
      flex-basis: 100%;

      .container {
        width: 80%;
        min-height: unset;
        height: unset;
        max-height: 300px;
      }
    }
  }
}
