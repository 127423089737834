section .settings-container {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.8rem;
  margin: 10rem auto 10rem auto;

  button {
    @include blue-card();
    width: 150px;
    height: 90px;
    padding: 0.2rem;
    transition: all 0.3s ease-in-out;
    font-size: 1.2rem;

    &:hover {
      color: $color-blue;
    }
  }

  @media only screen and (max-width: $bp-tablet){
    margin: 5rem auto 5rem auto;
  }
}

section .update-email {
  width: 400px;
  margin: auto;
  @include card();
}
