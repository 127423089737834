@import "./mixins";

$font1: "Manrope", sans-serif;

$color-blue: #0c59e0;
$color-blueish: #7497d9;
$color-light-blue: #9abaef;
$color-pale-blue: rgba(1, 124, 255, 0.1);
$color-pale-blue2: rgb(197, 220, 255);
$color-error-red: rgb(252, 99, 99);
$color-warning-yellow: rgb(252, 219, 99);
$color-gray: #c3c3c3;

$bp-tablet: 960px;
$bp-phone: 480px;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  text-rendering: geometricPrecision;
  font-size: 16px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  font-family: $font1;
  z-index: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  cursor: default;
}

a {
  text-decoration: none;
  cursor: pointer;
}

button {
  border: 0;
  padding: 0;
  background: transparent;
  font-size: inherit;
  cursor: pointer;
}

button:focus {
  outline: 0;
}

.display-block {
  display: block;
}

.wave-wrapper {
  width: 100%;
  height: 800px;
  position: absolute;
  left: 50%;
  top: 300px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 0;

  @media only screen and (max-width: $bp-phone) {
    top: 0;
    width: 100vw;
  }
}

.wave {
  width: 1000px;
  height: 1025px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -500px;
  margin-top: -500px;
  border-radius: 40%;
  background: rgba(1, 124, 255, 0.1);
  animation: wave 20s infinite linear;
  z-index: 0;

  @media only screen and (max-width: $bp-phone) {
    left: 60%;
  }
}

.landing-page-gs-btn-container {
  margin: 50px auto;
  display: flex;

  a {
    margin: auto;
    font-weight: 600;
    background: $color-pale-blue2;
    letter-spacing: 0.5px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    color: $color-blue;
    padding: 1rem 2rem;

    &:hover,
    &:focus-visible {
      background: $color-blueish;
      color: white;
    }
  }
}

.super-button {
  padding: 0.5rem;
  font-weight: 600;
  background: $color-pale-blue2;
  letter-spacing: 0.5px;
  border: 1px solid $color-blueish;
  border-radius: 4px;
  transition: all 0.2s ease-in;
  color: $color-blue;

  &:hover,
  &:focus {
    background: $color-blueish;
    color: white;
  }
}

.scroll-top-btn {
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 1000;
  transform: translateY(60px);
  transition: all 0.3s ease;

  &.visible {
    transform: translateY(0);
  }

  img {
    width: 50px;
    height: 50px;
    padding: 10px;
  }
}

.error-modal {
  h4 {
    padding: 1rem;
    font-size: 1.2rem;
    cursor: pointer;

    &.error {
      background: $color-error-red;
    }

    &.warning {
      background: $color-warning-yellow;
    }
  }
}

.info-modal {
  h4 {
    padding: 1rem;
    font-size: 1.2rem;
    cursor: pointer;
    background: $color-pale-blue2;
  }

  button {
    border: 2px solid $color-blueish;
    background: $color-pale-blue;
    padding: 0.7rem;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: all 0.3s ease;

    &:hover {
      background: $color-light-blue;
    }
  }

  .bd {
    background: $color-pale-blue2;
    display: flex;
    justify-content: center;
    padding: 1rem;
  }
}

.go-back-btn {
  font-size: 1.2rem;
  display: flex;
  align-items: center;

  img {
    height: 1.2rem;
    margin-right: 0.2rem;
  }

  p {
    cursor: pointer;
  }
}

.currency-menu {
  margin: 0.5rem auto;

  & > button {
    border: 1px solid $color-blueish;
    border-radius: 5px;
    background: $color-pale-blue;
    padding: 0.5rem;
    font-size: 0.8rem;
    transition: all 0.3s ease;

    &:hover {
      background: $color-light-blue;
    }
  }
}

@keyframes pop-down {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(12px);
  }
  50% {
    transform: translateY(0);
  }
  70% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes pop-up {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(-12px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes wave {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
