header.landing-page {
  @include header();

  h1 {
    cursor: default;
  }

  nav {
    display: flex;
    gap: 1.2rem;
    margin-bottom: 0.4rem;

    & > * {
      @include hover-underline(2px, $color-blue);
      color: $color-blue;
      font-weight: 600;
      text-align: center;
    }
  }

  @media only screen and (max-width: $bp-tablet) {
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
    gap: 1.3rem;

    h1 {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: $bp-phone) {
    nav {
      gap: 1rem;
      & > *::before {
        display: none;
      }
    }
  }
}

.landing-page-main {
  width: 90%;
  margin: 60px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  small {
    font-size: 1.2rem;
  }

  p {
    text-align: center;
    font-size: 3rem;
    color: black;
    font-weight: bold;
  }

  img {
    margin-top: 20px;
    cursor: pointer;
    padding: 1rem;

    &:hover {
      animation: pop-down 3s infinite;
    }
  }

  @media only screen and (max-width: $bp-tablet) {
    p {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: $bp-phone) {
    p {
      font-size: 1.5rem;
    }

    img {
      animation: pop-down 2s infinite;
    }
  }
}

.landing-page-about {
  width: 65ch;
  max-width: 1200px;
  margin: 200px auto 0 auto;

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
    text-wrap: balance;
  }

  @media only screen and (max-width: $bp-tablet) {
    margin-top: 100px;
    width: 80%;
  }

  @media only screen and (max-width: $bp-phone) {
    h2 {
      font-size: 1.6rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
}

.landing-page-features {
  width: 80%;
  max-width: 1200px;
  margin: 100px auto 0 auto;

  & > h2 {
    font-size: 2rem;
    text-align: center;
  }

  .card {
    display: flex;
    gap: 0.5rem;
    margin-top: 20px;

    div {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 0 50px;
      margin-top: 3rem;

      h3 {
        font-size: 1.2rem;
        font-weight: bold;
      }

      p {
        font-size: 1.5rem;
      }
    }

    .graphic {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 80%;
      }
    }
  }

  .card:nth-child(even) {
    flex-direction: row-reverse;
  }

  .card:nth-child(1) {
    margin-top: 0;
  }

  @media only screen and (max-width: $bp-tablet) {
    margin-top: 100px;
    & > * {
      height: auto;
    }

    .card {
      flex-direction: column;
      gap: 0;
      div {
        width: 100%;
        padding: 0;
        margin-top: 1rem;

        h3 {
          font-size: 1rem;
          font-weight: bold;
        }

        p {
          font-size: 1.2rem;
        }
      }

      .graphic {
        width: 100%;
        height: 100px;
      }
    }

    .card:nth-child(even) {
      flex-direction: column;
    }
  }
}

.landing-page-faq {
  width: 70ch;
  margin: 100px auto 0 auto;

  h2 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 0.5em;
  }

  .field {
    .question {
      margin-top: 0.3em;
      font-weight: bold;
    }

    .answer {
      padding: 0.3em 1em;
    }
  }

  @media only screen and (max-width: $bp-tablet) {
    width: 80%;
  }
}

.landing-page-partners {
  width: 80%;
  max-width: 700px;
  margin: 100px auto 0 auto;

  & > h2 {
    font-size: 2rem;
    text-align: center;
  }

  & > .partner-container {
    width: 100%;
    height: 100px;
    margin-top: 2rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: flex-start;
    @media only screen and (max-width: $bp-phone) {
      gap: 1rem;
    }

    & > img {
      height: 100px;
      @media only screen and (max-width: $bp-phone) {
        height: 75px;
      }
    }

    & > div {
      width: 100%;
      @media only screen and (max-width: $bp-phone) {
        font-size: 1em;
      }

      & > button {
        background-color: $color-pale-blue2;
        color: $color-blue;
        border-radius: 5px;
        padding: 0.5em 0.7em;
        margin-top: 10px;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        transition: all 0.2s ease;
        letter-spacing: 0.4px;
        @media only screen and (max-width: $bp-phone) {
          padding: 0.3em 0.5em;
          font-size: 0.9em;
        }

        &:hover {
          background-color: $color-blueish;
          color: white;
        }

        & > img {
          height: 20px;
          @media only screen and (max-width: $bp-phone) {
            height: 17px;
          }
        }
      }
    }
  }
}
