div.dashboard {
  width: 100%;
}

div.dashboard header {
  @include header();

  .header-text {
    display: flex;
    align-items: flex-end;

    & * {
      cursor: pointer;
    }

    p {
      margin: 0 0 0.4rem 0.4rem;
    }
  }

  nav {
    display: flex;
    gap: 1.3rem;
    margin-bottom: 0.4rem;

    button {
      color: $color-blue;
      font-weight: 600;
      transition: all 0.3s ease;
    }

    button:hover {
      color: $color-light-blue;
    }
  }

  @media only screen and (max-width: $bp-tablet) {
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .header-text {
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 2rem;
      }
    }

    nav {
      margin: 0;
    }
  }

  @media only screen and (max-width: $bp-phone) {
    margin-top: 1rem;

    .header-text {
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 1.6rem;
      }
    }

    nav {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      align-items: self-start;
    }
  }
}

div.dashboard .component {
  width: 90%;
  margin: 50px auto;
}
